import { Base } from '@/components/Base';
import { Consent } from '@/components/Consent';
import { Page4XX } from '@/components/Page4XX';
import { Tracking } from '@/components/Tracking';
import { GetStaticProps, NextPage } from 'next';

const Page: NextPage = () => {
  return (
    <Base
      content={
        <>
          <Consent />
          <Tracking />
          <Page4XX status={404} />
        </>
      }
    />
  );
};

export default Page;

export const getStaticProps: GetStaticProps = () => {
  return {
    props: {},
    revalidate: 3600,
  };
};
